import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from './../components/layout';
import Seo from './../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faLinkedin, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import './../styles/contact.scss';

const faHash = {
  email: faEnvelope,
  phone: faPhone,
  twitter: faTwitter,
  linkedin: faLinkedin,
  facebook: faFacebook,
  instagram: faInstagram,
  youtube: faYoutube
};

const ContactPage = ({data}) => {

  return <>
    <Layout>
      <Seo title="Contact" />
      <h1>{data.strapiFooter.contact_heading}</h1>
      <p>{data.strapiFooter.contact_description}</p>

      <p class="contact-subheading">{data.strapiFooter.social_heading}</p>
      {data.strapiFooter.footer_social_link.map(sl => <>
        <p class="contact-link">
          <a href={(sl.icon === 'email' ? 'mailto:' : '') + sl.url}>
            <FontAwesomeIcon icon={faHash[sl.icon]} />
            {sl.display_text}
          </a>
        </p>
      </>)}
      
      {/* <p class="contact-subheading">{data.strapiFooter.navigation_heading}</p>
      {data.strapiFooter.footer_navigation_link.map(link => <>
        <p class="contact-link">
          <Link to={link.url}>{link.display_text}</Link>
        </p>
      </>)} */}
    </Layout>
  </>;
}

export default ContactPage;

export const pageQuery = graphql`
query contactQuery {
  strapiFooter {
    contact_description
    contact_heading
    navigation_heading
    social_heading
    footer_navigation_link {
      url
      display_text
    }
    footer_social_link {
      display_text
      icon
      url
    }
  }
}
`;
